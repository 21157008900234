import React from "react";
import "./order.css";
import { useReactToPrint } from 'react-to-print';
import { Navigate, useNavigate } from "react-router-dom";

const PrintButton = ({ componentRef }) => {

    const navigate = useNavigate();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


const placeOrder = () =>
{
  handlePrint();
     navigate("/"+"?dbnam="+localStorage.getItem("dbnam"))

}

  return (
    <div>
    <button className="cst "
        style={{fontSize: "36px", marginTop: "400px", background: "#d7f9f8" }} onClick={() => {placeOrder()}}>Print Token</button>
  </div>
  );
};

export default PrintButton;
