import React from "react";
import "./choosepayment.css";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import counter from "./counter.png";
import payment from "./payment.png";
import RazorpayComponent from "./RazorpayComponent";
const Payon = () => {
  const [paystate, setpaystate] = useState(false);
  const navigate = useNavigate();
  const navigatefunc = () => {
    navigate("/?dbnam=" + localStorage.getItem("dbnam"));
  };
  const checkOrder = async () => {
    console.log("place order called");
    await fetch("https://dev.api.amepos.io/kiosk_placeorder/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        is_paid: "1",
        transaction_no: localStorage.getItem("paymentidrazorpay"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("placeorder", data);
        if (data?.PlaceOrderArr[0]?.PlaceOrder === 3) {
          console.log("Order placed successfully!!");

          const orderDetails = data?.PlaceOrderArr[0]?.order_details;

          // Convert the object to a JSON string
          const orderDetailsString = JSON.stringify(orderDetails);

          // Store the JSON string in localStorage
          localStorage.setItem("orderdetails", orderDetailsString);

          localStorage.setItem("tokenno", data?.PlaceOrderArr[0]?.tokno);
          localStorage.setItem("ispaid", data?.PlaceOrderArr[0]?.is_paid);
          // handlePrint()

          navigate("/order");
        } else if (
          data?.PlaceOrderArr[0]?.PlaceOrder === 5 ||
          data?.PlaceOrderArr[0]?.PlaceOrder === 2 ||
          data?.PlaceOrderArr[0]?.PlaceOrder === 1
        ) {
          alert("API: Token full wait sometime ");
          return false;
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center",backgroundColor: "#c9f1ff"}}>
      {paystate ? (
        <>
          <button
            onClick={() => {
              checkOrder();
            }}
          >
            Check order
          </button>
        </>
      ) : (
        <>
          {" "}
          <RazorpayComponent
            setpaystate={setpaystate}
            checkOrder={checkOrder}
          ></RazorpayComponent>
          <br></br>
          <button
            onClick={() => {
              navigatefunc();
            }}
            style={{
              fontSize:"36px",
              width: "auto",
              backgroundColor: "red",
              border: "solid 0px",
              color: "white",
              marginLeft: "10px",
              padding:"10px 20px",
              borderRadius:"5px"
            }}
          >
            Cancel Order
          </button>
        </>
      )}
    </div>
  );
};

export default Payon;
