import React from "react";
import "./choosepayment.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import counter from "./counter.png";
import payment from "./payment.png";
import RazorpayComponent from "./RazorpayComponent";
const Choosepayment = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/cart");
  };
  //Selecting Paymode
  const payAt = async (pay) => {
    console.log(pay, "pay");
    //If pay at counter directly place order stating is_paid : 0,customer will pay at counter
    if (pay === "counter") {
      console.log("place order called");
      await fetch("https://dev.api.amepos.io/kiosk_placeorder/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: localStorage.getItem("deviceid"),
          dbname: localStorage.getItem("dbnam"),
          is_paid: 0,
          transaction_no: "",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("placeorder", data);
          if (data?.PlaceOrderArr[0]?.PlaceOrder === 3) {
            console.log("Order placed successfully!!");

            const orderDetails = data?.PlaceOrderArr[0]?.order_details;

            // Convert the object to a JSON string
            const orderDetailsString = JSON.stringify(orderDetails);

            // Store the JSON string in localStorage
            localStorage.setItem("orderdetails", orderDetailsString);

            localStorage.setItem("tokenno", data?.PlaceOrderArr[0]?.tokno);
            localStorage.setItem("ispaid", data?.PlaceOrderArr[0]?.is_paid);
            // handlePrint()

            navigate("/order");
          } else if (
            data?.PlaceOrderArr[0]?.PlaceOrder === 5 ||
            data?.PlaceOrderArr[0]?.PlaceOrder === 2 ||
            data?.PlaceOrderArr[0]?.PlaceOrder === 1
          ) {
            alert("API:Token full wait for sometime");
            return false;
          }
        })
        .catch((error) => console.error(error));
    } else if (pay === "online") {
      console.log("pending");
      navigate("/payon");
    }
  };

  return (
    <div className="choosepayment">
      <div style={{ width: "80%" }}>
        <h4
          className=""
          style={{ fontWeight: "600", fontSize: "50px", marginBottom: "150px" }}
        >
          Where would you like to checkout ?
        </h4>
        <div className="row mt-4">
          <div className="col">
            <div
              className="paymentbox"
              onClick={(e) => {
                payAt("counter");
              }}
            >
              <h5 className="payhead">At Counter</h5>
              <p className="payp">(Cash Only)</p>
              <img style={{ width: "300px" }} src={counter}></img>
            </div>
          </div>
          <div className="col">
            <div
              className="paymentbox"
              onClick={(e) => {
                payAt("online");
              }}
            >
              <h5 className="payhead">Online Payment</h5>
              <p className="payp">(Cash & Credit Only)</p>
              <img style={{ width: "300px" }} src={payment}></img>
            </div>
          </div>
        </div>
      </div>
      <button
        style={{
          position: "absolute",
          bottom: "65px",
          width: "750px",
          border: "0px solid",
          backgroundColor: "#707070",
          color: "white",
          fontSize: "25px",
          padding: "10px",
          fontWeight: "700",
          height: "80px",
        }}
        onClick={back}
      >
        BACK
      </button>
    </div>
  );
};

export default Choosepayment;
