// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  text-align: center;\n  font-family: 'Montserrat', sans-serif;\n}\n.app-container{\n  padding: 20px;\n  \n}\n.brand{\n\n  font-weight: 600;\n  font-size: 30px;\n}\n.branddiv {\n  /* margin-top: 50px; */\n  position: absolute;\n  /* top: 90%; */\n  width: 90%;\n  bottom: 2%;\n}\n.row.cs {\n  margin-right: 0px;\n}\n\n@media only screen and (min-width: 600px) {\n  .branddiv {\n    /* margin-top: 50px; */\n    position: absolute;\n    /* top: 90%; */\n    width: 95%;\n    bottom: 2%;\n  }\n\n}\n\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,aAAa;;AAEf;AACA;;EAEE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,UAAU;AACZ;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,UAAU;EACZ;;AAEF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');\n\n.App {\n  text-align: center;\n  font-family: 'Montserrat', sans-serif;\n}\n.app-container{\n  padding: 20px;\n  \n}\n.brand{\n\n  font-weight: 600;\n  font-size: 30px;\n}\n.branddiv {\n  /* margin-top: 50px; */\n  position: absolute;\n  /* top: 90%; */\n  width: 90%;\n  bottom: 2%;\n}\n.row.cs {\n  margin-right: 0px;\n}\n\n@media only screen and (min-width: 600px) {\n  .branddiv {\n    /* margin-top: 50px; */\n    position: absolute;\n    /* top: 90%; */\n    width: 95%;\n    bottom: 2%;\n  }\n\n}\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
