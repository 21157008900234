import React from "react";
import "./order.css";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import orderno from "./orderno.png";
import { apiURI } from "../../config/config";
import { useRef } from 'react';


import PrintButton from "./printbutton";
import Print from "./printcomp";

const Order = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [printData, setprintData] = useState();

  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const storedOrderDetailsString = localStorage.getItem("orderdetails");

    // Parse the JSON string back into an object
    const storedOrderDetails = JSON.parse(storedOrderDetailsString);

    setprintData(storedOrderDetails)

  }, []);

  return (
    <div className="order">
      <h3 className="orderhead"  >
        Please Proceed to the front counter to fulfill the payment
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <img className="odimg" src={orderno}></img>
          <p
            style={{ fontSize: "100px", marginTop: "-460px", fontWeight: "600" }}
          >
            {localStorage.getItem("tokenno")}
          </p>
        </div>
      </div>


      <Print ref={componentRef} printData={printData} />
      <PrintButton componentRef={componentRef} />
    </div>
  );
};

export default Order;
