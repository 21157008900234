import React from "react";
import "./cart.css";
import { useNavigate } from "react-router-dom";
import shop from "./shop.png";
import burgercoke from "./burgercoke.png";
import { useSelector } from "react-redux";
import { apiURI } from "../../config/config";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Itemcard from "./itemCard";

const Customize = () => {
  const navigate = useNavigate();
  const plucode = useSelector((state) => state.constVar.itemid);
  const [cartItems, setcartItems] = useState();
  const [Tables, setTables] = useState();
  const [selectedTable, setselectedTable] = useState();
  const [subtotal, setsubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const orderMore = () => {
    navigate("/category");
  };

  const completeOrder = async () => {
    console.log(
      "details",
      selectedTable,
      localStorage.getItem("dbnam"),
      localStorage.getItem("deviceid")
    );
    if (cartItems[0]?.GetCart === 3) {
      localStorage.setItem("payamount", total)
      //getting livekey from backend for online payment
      await fetch(apiURI.URL + "kiosk_livekey/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "dbname": localStorage.getItem("dbnam")
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("modify cart", data);
          localStorage.setItem("livekey", data?.client_live_key_id)
        })
        .catch((error) => console.error(error));

      navigate("/choosepayment");
    } else {
      alert("no items in cart");
    }
  };

  //Modify cart data
  const modifyCart = async (itemcount, sno, tokno, rateid, plucod, usp) => {
    await fetch(apiURI.URL + "qrcode_modifycartitem/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        sno: sno,
        tokno: tokno,
        tqty: itemcount,
        rateid: rateid,
        plucod: plucod,
        usellp: usp,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("modify cart", data);
        getCart();
      })
      .catch((error) => console.error(error));

    console.log(itemcount, "itemcount in modifyCart");
  };
//Delete Item in Cart API
  const deleteItem = async (sno, tokno) => {
    await fetch(apiURI.URL + "qrcode_deletecart/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        sno: sno,
        tokno: tokno,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("delete item", data);
        getCart();
      })
      .catch((error) => console.error(error));
  };
//Get Cart API 
  const getCart = async () => {
    await fetch(apiURI.URL + "qrcode_getcart/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
        custid: "1",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        
        setcartItems(data?.GetCartArr);
        let subtotal = data?.GetCartArr.reduce(
          (acc, item) => acc + item.SellPrice,
          0
        );
        let tax = data?.GetCartArr.reduce((acc, item) => acc + item.GST, 0);
        let total = data?.GetCartArr.reduce(
          (acc, item) => acc + item.Amount,
          0
        );
        console.log(subtotal, tax, total, "after get");

        setsubtotal(subtotal);
        setTax(tax);
        setTotal(total);
        localStorage.setItem("tokenno", data?.GetCartArr[0]?.tokno);
      })
      .catch((error) => console.error(error));
  };
// fetch table details API for qrcode application
  const getTable = async () => {
    await fetch(apiURI.URL + "qrcode_tabledetails/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: localStorage.getItem("deviceid"),
        dbname: localStorage.getItem("dbnam"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        
        setTables(data?.TableDetailsArr);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getCart();
    getTable();
  }, []);

  return (
    <div className="cart">
      <div>
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
            <img src={shop} style={{ width: "80%" }} />
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
            <div style={{ textAlign: "left" }}>
              <h4 className="mb-0 mt-4 ct">Cart</h4>
              <p className="ctp">Review your Items</p>
            </div>
          </div>
        </div>

        <div style={{ height: "60vh", overflowY: "auto" }}>
          {cartItems?.length > 0 &&
            cartItems.map((item) => (
              <Itemcard
                item={item}
                modifyCart={modifyCart}
                deleteItem={deleteItem}
              ></Itemcard>
            ))}
        </div>
        <div style={{ height: "10vh" }}></div>
      </div>

      <div className="bill" style={{ height: "20vh" }}>
        <div className="row">
          <div className="col">
            <div style={{ textAlign: "left" }}>
              <h6 className="billdet">Sub Total</h6>
              <h6 className="billdet">Tax</h6>
              <h6 className="billdet">Total</h6>
              <button
                className="cst mt-4"
                style={{ backgroundColor: "#E3FF2D", color: "black" }}
                onClick={orderMore}
              >
                Order More
              </button>
            </div>
          </div>
          <div className="col">
            <div style={{ textAlign: "right" }}>
              <h6 className="billdet">Rs. {subtotal}</h6>
              <h6 className="billdet">Rs. {tax}</h6>
              <h6 className="billdet">Rs. {total}</h6>
              <button
                className="cst mt-4"
                style={{ backgroundColor: "#0EF170", color: "white" }}
                onClick={completeOrder}
              >
                Complete Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customize;
