import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const RazorpayComponent = ({ setpaystate, checkOrder }) => {
  const navigate = useNavigate();


  useEffect(() => {
    let paymentvalue = localStorage.getItem("payamount");
    let apiValue = Math.round(parseFloat(paymentvalue) * 100);
    const options = {
      key: localStorage.getItem("livekey"), // Enter the Key ID generated from the Dashboard
      amount: "100",
      currency: "INR",
      description: "AMEPOS",
      image: "https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg",
      // prefill: {
      //   email: "kioskclient@example.com",
      //   contact: "+919900000000",
      // },
      config: {
        display: {
          blocks: {
            other: {
              name: "Payment modes",
              instruments: [
                {
                  method: "card",
                },
                {
                  method: "netbanking",
                },
                {
                  method: "upi",
                },
              ],
            },
          },
          hide: [
            // {
            //   method: "upi"
            // }
          ],
          sequence: ["block.utib", "block.other"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },
      handler: async (response) => {
        alert(response.razorpay_payment_id);
        await setpaystate(true);
        await localStorage.setItem(
          "paymentidrazorpay",
          response.razorpay_payment_id
        );

        // checkOrder();
      },
      modal: {
        ondismiss: function () {
          if (window.confirm("Are you sure, you want to close the form?")) {
            console.log("Checkout form closed by the user");
            rzp1.destroy();
          } else {
            console.log("Complete the Payment");
          }
        },
      },
    };

    const rzp1 = new window.Razorpay(options);

    const handleClick = (e) => {
      rzp1.open();
      e.preventDefault();
    };
  
    document.getElementById("rzp-button1").addEventListener("click", handleClick);

    // Cleanup event listener when the component unmounts
    // return () => {
  
    //   document.getElementById('rzp-button1').removeEventListener('click', () => { });
    // };
  }, []);

  return (
    <div>
      <button
        id="rzp-button1"
        style={{
          fontSize:"36px",
          width: "auto",
          backgroundColor: "#15CACA",
          border: "solid 0px",
          color: "white",
          padding:"10px 20px",
          borderRadius:"5px"
        }}
      >
        Pay
      </button>
    </div>
  );
};

export default RazorpayComponent;
