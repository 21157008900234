import React from "react";
import { apiURI } from "../../config/config";
const Sidebar = ({
  setcategory,
  sidebarval,
  setsubCategoryData,
  inventoryDetails,
  homeDetails,
}) => {
  
// Category navigation
  const navigateCategory = async (cat, dept_id) => {
    setcategory(cat);
    if (cat === "home") {
      homeDetails();
    } else {
      if (dept_id === undefined || dept_id === "") {
        console.log("id not defined");
      } else {
        try {
          await fetch(apiURI.URL + "qrcode_getcategory/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              device_id: localStorage.getItem("deviceid"),
              deptid: dept_id,
              dbname: localStorage.getItem("dbnam"),
            }),
          })
            .then((response) => response.json())
            .then((data) => {
            
              setsubCategoryData(data);

              inventoryDetails(data[0]?.catid);
            })
            .catch((error) => console.error(error));
        } catch (error) {
          console.log(error, "error");
        }
      }
    }
  };

  

  return (
    <div className="menubar">
      <div
        className="menucat mb-2"
        onClick={(e) => {
          navigateCategory("home");
        }}
      >
        Home
      </div>

      {sidebarval?.department_data !== undefined &&
        sidebarval?.department_data.map((menuoptions, index) => (
          <div
            className="menucat mb-2"
            onClick={(e) => {
              navigateCategory(menuoptions?.deptnam, menuoptions?.deptid);
            }}
          >
            {menuoptions?.deptnam}
          </div>
        ))}
    </div>
  );
};

export default Sidebar;
