
import './App.css';
import Home from "./Pages/Home/home"
import 'bootstrap/dist/css/bootstrap.min.css';
import Choose from './Pages/choose/choose';
import Category from './Pages/category/category';
import Customize from './Pages/customize/customize';
import Cart from "./Pages/cart/cart"
import {  Routes, Route ,Redirect, Navigate} from 'react-router-dom';
import Choosepayment from './Pages/choosepayment/choosepayment';
import Order from './Pages/order/order';
import Trackorder from './Pages/track/trackorder';
import Payon from './Pages/choosepayment/payon';

function App() {
  return (
    
  
    <div className="App">
    <Routes>
    <Route exact path="/" element={<Home/>} />
    <Route path="/home" element={<Home/>} />
    <Route path="/choose" element={<Choose/>} />
    <Route path="/trackorder" element={<Trackorder/>} />
    <Route path="/category" element={<Category/>} />
    <Route path="/customize" element={<Customize/>} />
    <Route path="/cart" element={<Cart/>} />
    <Route path="/choosepayment" element={<Choosepayment/>} />
    <Route path="/order" element={<Order/>} />
    <Route path="/payon" element={<Payon/>} />
    </Routes>
    </div>
    
  );
}

export default App;
