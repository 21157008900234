import React from "react";
import "./cartbar.css";
import carticon from "./carticon.png";
import { useNavigate } from "react-router-dom";
const Cartbar = ({ cartcheck }) => {
  const navigate = useNavigate();

  console.log("cartcheck", cartcheck?.GetCartArr[0]?.GetCart);
  const viewCart = () => {
    navigate("/cart");
  };
  return (
    <div className="cartcomponent">
      <div className="row m-0">
        <div className="col">
          {cartcheck?.GetCartArr[0]?.GetCart !== 3 ? (
            <div className="cartcomp">
              <h3
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                Cart is Empty
              </h3>
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                Add Items to you cart
              </p>
            </div>
          ) : (
            <div className="cartcomp">
            <h3
              style={{
                fontSize: "31px",
                fontWeight: "700",
                marginBottom: "0px",
              }}
            >
              Cart is filled
            </h3>
            <p
              style={{
                fontSize: "22px",
                fontWeight: "700",
                marginBottom: "0px",
              }}
            >
              Click view to place order
            </p>
          </div>
          )}
        </div>
        <div className="col">
          {cartcheck?.GetCartArr[0]?.GetCart === 3 ? (
            <div className="viewbt">
              <p
                className="viewbttext"
                onClick={viewCart}
                style={{
                fontSize: "22px",
          
                }}
              >
                View your cart
              </p>
              <img
                src={carticon}
                style={{ width: "42px", height: "56px" }}
              ></img>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cartbar;
