import React, { forwardRef } from "react";
import "./order.css";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import orderno from "./orderno.png";

import "./print.css";
import { useEffect } from "react";

const Printcomp = forwardRef((props, ref) => {

  console.log("inside print comp", props?.printData)



  return (
    <div ref={ref} className="print-only " style={{ padding: "10px" }}>
      <div class="ticket">
        {/* <img src={orderno}></img> */}
        <p
          style={{
            textAlign: "center",
            marginBottom: "0px",
            fontWeight: "600",
          }}
        >
          AMEPOS KIOSK
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Order No : {localStorage.getItem("tokenno")}
        </p>


        <table style={{ width: "288px", marginBottom: "10px" }}>
          <thead>

            <tr>
              <th class="quantity ">SL</th>
              <th class="description">ITEM</th>
              <th class="price">QTY</th>
              <th class="pricetotal">TOTAL </th>
            </tr>
          </thead>
          <tbody>


            {props?.printData?.length > 0 && props?.printData?.map((item) => (
            <tr>
              <td class="quantity">1</td>
              <td class="description">{item?.prodnam}</td>
              <td class="price">{item?.tqty}</td>
              <td class="pricetotal">Rs.{item?.amount}</td>
            </tr>))}
            <tr>  
              <td class="quantity"></td>
               <td class="description">TOTAL</td>
              <td class="price"></td>
              <td class="pricetotal">{props?.printData?.length > 0 && props?.printData?.reduce((accumulator,currentvalue) => accumulator+currentvalue?.amount,0 )}
              </td>
             </tr>


             
          </tbody>
        </table>
        {localStorage.getItem("ispaid") == 0 ?
          <p
            style={{
              textAlign: "center",
              marginBottom: "0px",
              fontWeight: "600",
            }}
          >
            Please pay at the counter
          </p> :
          <p
            style={{
              textAlign: "center",
              marginBottom: "0px",
              fontWeight: "600",
            }}
          >
            Visit counter to get your order
          </p>}
      </div>
    </div>
  );
});

export default Printcomp;
